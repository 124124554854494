import { Modal } from 'antd';

const { confirm } = Modal;

export const showDeleteConfirm = (onDelete) => {
  confirm({
    className: 'deleteModal',
    title: 'Are you sure you want to delete?',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      onDelete();
    },
  });
};
