import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AuthComponent.module.scss';
import SideBar from '../sidebar/SideBar';

const AuthComponent = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.sideBarSection}>
        <SideBar toggleSidebar={toggleSidebar} collapsed={collapsed} />
      </div>
      <div className={`${styles.content} ${collapsed ? styles.collapsed : ''}`}>{children}</div>
    </div>
  );
};

AuthComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthComponent;
