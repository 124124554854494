import { Modal } from 'antd';

const { confirm } = Modal;

export const showStatusConfirm = (onConfirm, key, text) => {
  /* eslint-disable no-console */
  console.log(onConfirm, 'onConfirm');
  confirm({
    className: 'deleteModal',
    title: 'Are you sure you want to change the status?',
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'No',
    onOk() {
      onConfirm(key, text);
    },
  });
};
