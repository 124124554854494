import Login from './pages/login/Login';
import MainPage from './pages/main-page/MainPage';
import Vacancy from './pages/vacancy/Vacancy';
import Vacancies from './pages/vacancies/Vacancies';
import Blog from './pages/blog/Blog';
import Blogs from './pages/blogs/Blogs';
import CV from './pages/cv/CV';
import Orders from './pages/orders/Orders';

export const authRoutes = [
  {
    route: '/login',
    Component: Login,
  },
];
export const routes = [
  {
    route: '/',
    Component: MainPage,
  },
  {
    route: '/orders',
    Component: Orders,
    name: 'Orders',
  },
  {
    route: '/create-vacancy',
    Component: Vacancy,
  },
  {
    route: '/edit-vacancy/:id',
    Component: Vacancy,
  },
  {
    route: '/vacancies',
    Component: Vacancies,
    name: 'Vacancies',
  },
  {
    route: '/create-blog',
    Component: Blog,
  },
  {
    route: '/blogs',
    Component: Blogs,
    name: 'Blog',
  },
  {
    route: '/edit-blog/:id',
    Component: Blog,
  },
  {
    route: '/cv',
    Component: CV,
    name: 'CV',
  },
];
