import React from 'react';

const FireSvg = () => {
  return (
    <svg width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.715 19.1539L29.6908 19.0648C26.1434 8.74771 15.5729 1.42309 15.1233 1.11791L13.7589 0.1875L13.8473 1.75065C13.8473 1.8177 14.096 7.87675 7.76343 12.9162C0.884993 18.3947 0.925147 22.9131 0.941173 25.3397L0.949274 25.6746C0.949274 32.292 6.18559 37.2399 13.6771 38.0555C13.2693 37.9961 12.8527 37.9191 12.4347 37.8004C10.0188 37.1081 8.60621 35.5375 8.33332 33.2746L8.26915 32.8281C8.05244 31.0788 7.77143 28.9127 11.8969 24.8037C13.4379 23.2703 14.7782 21.6178 15.8859 19.8983L16.4397 19.0349L17.1701 19.7792C19.3531 21.99 21.0226 24.2231 22.1463 26.3966C23.1816 28.3172 23.2459 31.3542 23.1655 32.0017C22.7723 35.3068 20.196 37.7706 16.7447 38.128C16.5197 38.1517 16.183 38.1748 15.7707 38.1834C15.807 38.1835 15.8415 38.1875 15.8779 38.1875C24.0324 38.1875 30.4132 32.1059 30.4132 24.3422C30.4132 22.4813 29.739 19.2879 29.715 19.1539Z"
        fill="url(#paint0_linear_97_1645)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_97_1645"
          x1="3.20947"
          y1="-1.41767"
          x2="38.3941"
          y2="20.0855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9EA48" />
          <stop offset="0.494792" stopColor="#FE6D1D" />
          <stop offset="1" stopColor="#FF0000" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FireSvg;
