import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { routes } from '../../routes';
import { useServices } from '../../services/useServices';
import styles from './Sidebar.module.scss';
import OpenSvg from '../svgs/OpenSidebarSvg';
import CloseSvg from '../svgs/CloseSvg';
import { showLogOutConfirm } from '../log-out-modal/LogOutModal';

const SideBar = ({ toggleSidebar, collapsed }) => {
  const { logOut } = useServices();

  return (
    <div className={`${styles.sidebar} ${collapsed ? styles.collapsed : ''}`}>
      <div className={styles.toggleIcon} onClick={toggleSidebar}>
        {collapsed ? <OpenSvg /> : <CloseSvg />}
      </div>
      {routes.map(
        ({ route, name }) =>
          name && (
            <div key={name}>
              <NavLink
                to={`${route}`}
                className={({ isActive }) => (isActive ? styles.selectedLink : '')}
              >
                {name}
              </NavLink>
            </div>
          )
      )}
      {!collapsed && (
        <Button type="danger" onClick={() => showLogOutConfirm(logOut)}>
          Log out
        </Button>
      )}
    </div>
  );
};
SideBar.propTypes = {
  toggleSidebar: PropTypes.func,
  collapsed: PropTypes.bool,
};
export default SideBar;
