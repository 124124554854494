/* eslint-disable no-console */
import { Table } from 'antd';
import React, { useState, useEffect } from 'react';
import styles from './Orders.module.scss';
import { useOrders } from './useOrders';

const Orders = () => {
  const {
    getData,
    OrderColumns,
    orders: { count, ordersData },
  } = useOrders();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    getData((currentPage - 1) * pageSize, pageSize);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getData((page - 1) * pageSize, pageSize);
  };

  const shouldShowPagination = count > pageSize;

  return (
    <div className={styles.blogs}>
      <div className={styles.pageHeader}>
        <h1>Պատվերներ</h1>
      </div>
      <div>
        <div className="defaultTable ordersTable">
          <Table
            dataSource={ordersData || []}
            columns={OrderColumns}
            rowKey="id"
            pagination={
              shouldShowPagination
                ? {
                    total: count,
                    pageSize,
                    current: currentPage,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    onChange: handlePageChange,
                  }
                : false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;
