import { Modal } from 'antd';

const { confirm } = Modal;

export const showLogOutConfirm = (onConfirm) => {
  confirm({
    className: 'deleteModal',
    title: 'Are you sure you want to log out?',
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'No',
    onOk() {
      onConfirm();
    },
  });
};
