/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import TrashIconSvg from '../../components/svgs/TrashIconSvg';
import { useServices } from '../../services/useServices';
import { showDeleteConfirm } from '../../components/delete-modal/DeleteModal';
import { showStatusConfirm } from '../../components/contaceted-status-modal/ContacetedStatus';

export const useCv = () => {
  const { getTableData, deleteRequest, editOrderStatus, downloadCv } = useServices();
  const [cv, setCv] = useState({ count: 0, cvData: [] });
  const [selectedIds, setSelectedIds] = useState([]);
  const limit = 10;

  const deleteCv = (ids) => {
    deleteRequest('delete-cv', ids).then(() => getCvData(0, 10));
    setSelectedIds([]);
  };
  const getCvData = async (from, limit) => {
    const offset = from * limit;
    const res = await getTableData('cv', offset, limit);
    setCv({
      count: res.count,
      cvData: res.data,
    });
  };
  const changeStatus = async (id, text) => {
    try {
      await editOrderStatus(`cv/order-status/${id}`, text ? 'contacted' : 'New Lead');
      getCvData(0, limit);
    } catch (error) {
      /* eslint-disable no-console */
      console.error('Failed to change status:', error);
    }
  };
  const formatDate = (dateString) => {
    return dateString.replace('T', ' ').substring(0, 19);
  };
  const CvColumns = [
    {
      title: 'CV status',
      dataIndex: 'order_status',
      key: 'order_status',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            showStatusConfirm(changeStatus, record.id, text);
          }}
          className={`statusButton ${text ? 'contact' : 'newLead'}`}
        >
          {text ? 'Contacted' : 'New Lead'}
        </Button>
      ),
    },
    {
      title: 'Date and time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => formatDate(text),
    },
    {
      title: 'Vacancies',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Contact type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Contact details',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: 'cv file',
      dataIndex: 'cv',
      key: 'cv',
      render: (text, record) => (
        <Button
          className="cvText"
          onClick={() => downloadCv(text, record.id, `CV - ${record.title}`, '/cv', getCvData)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: ' Delete',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <div className="tableActions">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              showDeleteConfirm(() => deleteCv([record.id]));
            }}
          >
            <TrashIconSvg />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCvData(0, 10);
  }, []);
  return { CvColumns, cv, selectedIds, getCvData, showDeleteConfirm };
};
